import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'react-use';
import { RegisterSWOptions, useRegisterSW } from 'virtual:pwa-register/react';
import config from '@config';
import { makeLogger } from '@components/logger';
import { BroadcastChannel, Message, MessageType, createChannel } from '@components/message';

const channel = createChannel();

const logger = makeLogger('sw context');

export declare interface ServiceWorkerContextT {
  ready: boolean;
  needRefresh: [boolean, Dispatch<SetStateAction<boolean>>];
  updateServiceWorker: (reloadPage?: boolean) => void;
  channel: BroadcastChannel<Message>;
}

export const ServiceWorkerContext = createContext<ServiceWorkerContextT>({
  ready: false,
  needRefresh: [false, () => {}],
  updateServiceWorker: async () => {},
  channel,
});

export interface ServiceWorkerContextProviderProps {
  children: ReactNode;
}

export function ServiceWorkerContextProvider({ children }: ServiceWorkerContextProviderProps) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!ready) {
      let channelPing: ReturnType<typeof setInterval>;

      // wait for the service worker to listen on the channel
      const handle = (m: Message) => {
        if (m.type === MessageType.PONG) {
          channel.removeEventListener('message', handle);
          clearInterval(channelPing);
          logger.info('sw ready');
          setReady(true);
        }
      };

      channel.addEventListener('message', handle);
      channelPing = setInterval(() => {
        channel.postMessage({ type: MessageType.PING });
      }, 250);
    }
  }, [ready]);

  const options: RegisterSWOptions = useMemo(
    () => ({
      immediate: true,
      onRegisteredSW(url, r) {
        logger.info(`registered: ${url}`);

        if (r) {
          setInterval(() => {
            r.update();
          }, config.serviceWorker.updateCheckInterval);
        }
      },
      onNeedRefresh: () => {
        logger.debug('refresh needed');
      },
      onRegisterError(error) {
        logger.error('registration error', error);
      },
    }),
    [],
  );

  // register our service worker
  const { needRefresh, updateServiceWorker: update } = useRegisterSW(options);

  const updateServiceWorker = useCallback(
    (refresh?: boolean) => {
      logger.info('updating service worker...');
      update(refresh);
    },
    [update],
  );

  const sw: ServiceWorkerContextT = useMemo(() => {
    return {
      ready,
      needRefresh,
      updateServiceWorker,
      channel,
    };
  }, [ready, needRefresh, updateServiceWorker]);

  // ping the service worker every 5 seconds, keep it alive
  useInterval(
    () => {
      navigator.serviceWorker.controller?.postMessage('ping');
    },
    ready ? 5000 : null,
  );

  return <ServiceWorkerContext.Provider value={sw}>{children}</ServiceWorkerContext.Provider>;
}
