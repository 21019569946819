import { dateTypePolicy } from "../policies/date";
import { decimalTypePolicy } from "../policies/decimal";
import { jsonObjectTypePolicy } from "../policies/json-object";

export const scalarTypePolicies = {
  Adjustment: {
    fields: {
      creationDate: dateTypePolicy,
      executiveSummary: jsonObjectTypePolicy,
      lastUpdateDate: dateTypePolicy,
      lossDate: dateTypePolicy,
      recommendations: jsonObjectTypePolicy,
      reportDate: dateTypePolicy,
    },
  },
  AdjustmentAirframe: {
    fields: {
      creationDate: dateTypePolicy,
      gForceLanding: decimalTypePolicy,
      lastUpdateDate: dateTypePolicy,
      narrative: jsonObjectTypePolicy,
    },
  },
  AdjustmentAirframeCrewMember: { fields: { dateOfBirth: dateTypePolicy, narrative: jsonObjectTypePolicy } },
  AdjustmentAirframeCrewMemberExperience: { fields: { licenceIssueDate: dateTypePolicy } },
  AdjustmentAirframeCrewMemberFlightRole: {
    fields: { lastBaseCheck: dateTypePolicy, lastLineCheck: dateTypePolicy, lastMedicalCheck: dateTypePolicy },
  },
  AdjustmentAirframeEngineDamage: { fields: { narrative: jsonObjectTypePolicy } },
  AdjustmentAirframeEngineMaintenanceCheck: {
    fields: { endDate: dateTypePolicy, narrative: jsonObjectTypePolicy, startDate: dateTypePolicy },
  },
  AdjustmentAirframeMaintenanceCheck: {
    fields: { endDate: dateTypePolicy, narrative: jsonObjectTypePolicy, startDate: dateTypePolicy },
  },
  AdjustmentAirframePartDamage: { fields: { narrative: jsonObjectTypePolicy } },
  AdjustmentAllocation: { fields: { creationDate: dateTypePolicy } },
  AdjustmentCostExpensePayment: {
    fields: { dateOfChange: dateTypePolicy, dateOfPayment: dateTypePolicy, narrative: jsonObjectTypePolicy },
  },
  AdjustmentCostExpenseReserve: { fields: { dateOfChange: dateTypePolicy, narrative: jsonObjectTypePolicy } },
  AdjustmentFeePayment: {
    fields: { dateOfChange: dateTypePolicy, dateOfPayment: dateTypePolicy, narrative: jsonObjectTypePolicy },
  },
  AdjustmentFeeReserve: { fields: { dateOfChange: dateTypePolicy, narrative: jsonObjectTypePolicy } },
  AdjustmentFlight: { fields: { creationDate: dateTypePolicy, lastUpdateDate: dateTypePolicy } },
  AdjustmentIndemnityPayment: {
    fields: { dateOfChange: dateTypePolicy, dateOfPayment: dateTypePolicy, narrative: jsonObjectTypePolicy },
  },
  AdjustmentIndemnityReserve: { fields: { dateOfChange: dateTypePolicy, narrative: jsonObjectTypePolicy } },
  AdjustmentIndividual: { fields: { dateOfBirth: dateTypePolicy } },
  AdjustmentReport: { fields: { creationDate: dateTypePolicy } },
  Aerodrome: { fields: { latitude: decimalTypePolicy, longitude: decimalTypePolicy } },
  AirframeOperator: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  AirframeRegistration: { fields: { endDate: dateTypePolicy, startDate: dateTypePolicy } },
  Condition: { fields: { from: dateTypePolicy, to: dateTypePolicy } },
  ConstraintValue: { fields: { date: dateTypePolicy, numeric: decimalTypePolicy } },
  Denomination: { fields: { value: decimalTypePolicy } },
  FileMetadata: { fields: { narrative: jsonObjectTypePolicy } },
  FlightAerodrome: {
    fields: {
      actualArrival: dateTypePolicy,
      actualDeparture: dateTypePolicy,
      actualLanding: dateTypePolicy,
      actualTakeoff: dateTypePolicy,
      estimated: dateTypePolicy,
      scheduled: dateTypePolicy,
    },
  },
  LocationCoordinates: { fields: { latitude: decimalTypePolicy, longitude: decimalTypePolicy } },
  LossNotice: {
    fields: {
      creationDate: dateTypePolicy,
      lastUpdateDate: dateTypePolicy,
      lossDate: dateTypePolicy,
      narrative: jsonObjectTypePolicy,
      reportDate: dateTypePolicy,
    },
  },
  LossNoticeAirframe: { fields: { creationDate: dateTypePolicy, lastUpdateDate: dateTypePolicy } },
  LossNoticeAirframeEngineDamage: { fields: { narrative: jsonObjectTypePolicy } },
  LossNoticeAirframePartDamage: { fields: { narrative: jsonObjectTypePolicy } },
  LossNoticeFlight: { fields: { creationDate: dateTypePolicy, lastUpdateDate: dateTypePolicy } },
  Policy: {
    fields: {
      creationDate: dateTypePolicy,
      endDate: dateTypePolicy,
      lastUpdateDate: dateTypePolicy,
      startDate: dateTypePolicy,
    },
  },
  PolicyMarketCompanyLine: { fields: { lineSize: decimalTypePolicy } },
  Runway: { fields: { gradient: decimalTypePolicy, latitude: decimalTypePolicy, longitude: decimalTypePolicy } },
};
