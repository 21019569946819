import { useUserProfile } from '@hooks/api/users';
import { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import useSessionStorage from 'react-use/lib/useSessionStorage';
import type { User } from '@api/deianira/generated/graphql';
import { makeLogger } from '@components/logger';
import { Message, MessageType, useMessage } from '@components/message';
import { ServiceWorkerContext } from '@components/serviceworker';
import type { ApiError } from '@features/errors/types';
import { AuthContext, AuthContextT, AuthState } from './types';

enum SynchronisationState {
  STARTING = 'starting',
  SYNCHRONISING = 'syncronising',
  SYNCHRONISED = 'syncronised',
}

const logger = makeLogger('auth context');

export function AuthContextProvider({ children }: { children: ReactNode }) {
  const syncState = useRef(SynchronisationState.STARTING);
  const [authState, setAuthState] = useState<AuthState>(AuthState.AUTHENTICATING);

  const [userId, setUserId] = useState<string>();
  const [error, setError] = useState<ApiError>();

  const [next, setNext] = useSessionStorage<string>('next', '/dashboard');
  const [user, setUser] = useSessionStorage<User>('user');

  const { ready, channel } = useContext(ServiceWorkerContext);

  const handleMessage = useCallback((msg: Message) => {
    switch (msg.type) {
      case MessageType.AUTHENTICATED:
        logger.info('synchronised: authenticated');

        syncState.current = SynchronisationState.SYNCHRONISED;
        setUserId(msg.userId);
        break;

      case MessageType.LOGGED_OUT:
        logger.info('logged out');

        syncState.current = SynchronisationState.SYNCHRONISED;
        setUserId(undefined);
        setAuthState(AuthState.NOT_AUTHENTICATED);
        break;

      case MessageType.PONG:
        break;

      default:
        logger.warn({ msg }, 'unknown message');
        break;
    }
  }, []);

  // synchronise with the SW
  const { send: sendMessage } = useMessage(channel, handleMessage);
  useEffect(() => {
    if (ready) {
      logger.info('synchronising...');
      syncState.current = SynchronisationState.SYNCHRONISING;
      sendMessage({
        type: MessageType.SYNCHRONISE,
      });
    }
  }, [sendMessage, ready]);

  // load the user profile once we have the user id
  const [profile, { error: profileError }] = useUserProfile(userId);

  useEffect(() => {
    if (profile) {
      setUser(profile);

      // authenticated
      setAuthState(AuthState.AUTHENTICATED);
    }
  }, [profile, setUser]);

  useEffect(() => {
    if (profileError) {
      setAuthState(AuthState.ERROR);

      if (profileError.networkError) {
        const statusCode = 'statusCode' in profileError.networkError ? profileError.networkError.statusCode : 500;

        setError({
          statusCode,
          message: 'A network error has occurred',
          error: 'Network Error',
        });
      } else {
        setError({
          statusCode: 500,
          message: 'An unknown error has occurred',
          error: 'Error',
        });
      }
    }
  }, [profileError]);

  const login = useCallback(() => {
    logger.info('synchonising auth state...');
    sendMessage({
      type: MessageType.SYNCHRONISE,
    });

    setAuthState(state =>
      state === AuthState.ERROR || state === AuthState.NOT_AUTHENTICATED ? AuthState.AUTHENTICATING : state,
    );
  }, [sendMessage]);

  const logout = useCallback(
    (next?: string) => {
      logger.debug('logout');
      setNext(next || '/dashboard');
      sendMessage({ type: MessageType.LOGOUT });
    },
    [sendMessage, setNext],
  );

  const authContext = useMemo<AuthContextT>(
    () => ({
      state: authState,
      error: error!,
      next,
      user,
      login,
      logout,
    }),
    [authState, user, next, error, login, logout],
  );

  return <AuthContext.Provider value={authContext!}>{children}</AuthContext.Provider>;
}
