function parseBoolean(value: string | undefined, defaultValue: boolean): boolean {
  if (value === undefined || value === '') {
    return defaultValue;
  }

  return value.toLowerCase() === 'true' || parseInt(value, 10) > 0;
}

class SentryConfig {
  public enabled: boolean = false;

  public dsn: string | undefined;

  constructor() {
    // enabled
    this.enabled = parseBoolean(import.meta.env.SENTRY_ENABLED, false);

    // dsn
    const dsn = import.meta.env.SENTRY_DSN;
    if (dsn === undefined || dsn === '') {
      this.dsn = undefined;
    } else {
      this.dsn = dsn;
    }
  }
}

class AppConfig {
  public version: string = '0.0.0';

  public buildDate: string = new Date().toISOString();

  public networkDelay: number | undefined;

  public apolloDebug: boolean = false;

  constructor() {
    this.version = import.meta.env.APP_VERSION ?? '0.0.0';
    this.buildDate = import.meta.env.BUILD_DATE ?? new Date().toISOString();

    const delay = parseInt(import.meta.env.NETWORK_DELAY, 10);
    if (Number.isNaN(delay)) {
      this.networkDelay = undefined;
    }

    this.apolloDebug = parseBoolean(import.meta.env.APOLLO_DEBUG, false);

    this.networkDelay = delay;
  }
}

class MapboxConfig {
  public token: string;

  constructor() {
    this.token = import.meta.env.MAPBOX_TOKEN || import.meta.env.VITE_MAPBOX_TOKEN;
  }
}

class SyncFusionConfig {
  public licenseKey: string;

  constructor() {
    this.licenseKey = import.meta.env.SYNCFUSION_LICENSE_KEY || import.meta.env.VITE_SYNCFUSION_LICENSE_KEY;
  }
}

class ServiceWorkerConfig {
  public updateCheckInterval: number;

  constructor() {
    this.updateCheckInterval = 5000;
  }
}

class MuiConfig {
  public licenseKey: string;

  constructor() {
    this.licenseKey = import.meta.env.MUI_LICENSE_KEY || import.meta.env.VITE_MUI_LICENSE_KEY;
  }
}

const config = {
  app: new AppConfig(),
  sentry: new SentryConfig(),
  mapbox: new MapboxConfig(),
  syncfusion: new SyncFusionConfig(),
  serviceWorker: new ServiceWorkerConfig(),
  mui: new MuiConfig(),
};

export default config;
